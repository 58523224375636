<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" app clipped>
      <v-list dense>
        <router-link
          style="text-decoration: none; color: inherit"
          v-for="(item,index) in routes"
          :key="item.text + index"
          :to="item.link"

        >
          <v-list-item  v-if="(item .onlyAuth && $store.state.auth.status.loggedIn) || !item.onlyAuth" :key="item.text" link>

            <v-subheader  v-if="item.isSpacer && item.isSubHeader">{{$t(`routes.${item.text}`)}}</v-subheader>
            <v-divider v-if="item.isSpacer" ></v-divider>

            <v-list-item-action v-if="!item.isSpacer">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content v-if="!item.isSpacer">
              <v-list-item-title>
                {{ $t(`routes.${item.text}`) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>

        <v-list-item>
          <v-divider></v-divider>
        </v-list-item>

        <v-list-item @click="$store.dispatch('eula/politics', true)">
          <v-list-item-action>
            <v-icon>mdi-security</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t(`routes.security`) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="$store.dispatch('eula/terms', true)">
          <v-list-item-action>
            <v-icon>mdi-police-badge-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t(`routes.eula`) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-divider></v-divider>
          <v-select label="Language"
                    v-model="$i18n.locale"
                    :value = "$store.state.selectedLanguage"
                    :items="languages">

          </v-select>
          <span  style="font-size: 16px;">1.45 STABLE</span>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar app clipped-left dense>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title
        style="cursor: pointer"
        class="mr-12 align-center"
      >
        <span class="title">WoT-STATS Search <span v-if="$store.state.auth.status.loggedIn">| PREMIUM</span></span>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-icon
        v-if="$vuetify.theme.dark"
        class="nav-item"
        @click="$vuetify.theme.dark = !$vuetify.theme.dark"
      >mdi-moon-waning-crescent
      </v-icon>
      <v-icon
        v-if="!$vuetify.theme.dark"
        class="nav-item"
        @click="$vuetify.theme.dark = !$vuetify.theme.dark"
      >
        mdi-weather-sunny
      </v-icon>

      <v-menu
        v-if="$store.state.auth.status.loggedIn"
        :offset-y="true"
        bottom
        transition="slide-y-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn outlined v-bind="attrs" v-on="on">
            {{ $store.state.auth.user.userName }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="$store.dispatch('auth/logout')" class="cursor-pointer">
            <v-list-item-icon>
              <v-icon>mdi-exit-to-app</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <span style="cursor: pointer"  @click="authDialog = true" v-if="!$store.state.auth.status.loggedIn">{{$t('auth.authorize')}}</span>
    </v-app-bar>

    <v-main>
      <div class="backend-status" v-if="$store.state.backendStatus === 'incoming-update'">
        <v-alert
          outlined
          type="warning"
          prominent

        >
          <div class="text-h6">
            WoT-STATS undergoing updates
          </div>
          <div>
            <p>
              The WoT-STATS backend is receiving a major upgrade. While we test the changes, some features may not work properly!
            </p>
            <p>
              Серйозне оновлення WoT-STATS. Поки ми тестуємо зміни, деякі функції можуть не працювати належним чином!
            </p>
            <p>
              Серверная часть WoT-STATS получает серьезное обновление. Пока мы тестируем изменения, некоторые функции могут работать некорректно!
            </p>

          </div>
        </v-alert>
      </div>

      <router-view />
    </v-main>


    <v-dialog
      v-model="authDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{$t('auth.title')}}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Email"
                  required
                  v-model="authData.email"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-bind:label="$t('auth.password')"
                  type="password"
                  v-model="authData.password"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="authDialog = false"
          >
            {{$t('auth.cancel')}}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="Authorize"
          >
            {{$t('auth.login')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog
      transition="dialog-top-transition"
      max-width="80%"
      v-model="$store.state.eula.term"
    >
      <v-card>
        <v-toolbar
          dark
        >SOFTWARE END USER LICENSE AGREEMENT WOT-STATS.</v-toolbar>
        <v-card-text>
          <p> The WOT-STATS End User License Agreement ("EULA") is a legal agreement between you (an individual or entity) and WOT-STATS with respect to the WOT-STATS software(s) identified above, which may include related software components , media, printed materials and "online" or electronic documentation ("WOT-STATS Software"). By installing, copying or otherwise using the WOT-STATS software, you agree to be bound by the terms of this EULA. This license agreement constitutes the entire agreement regarding the program between you and WOT-STATS (referred to as the "licensor") and supersedes any prior proposals, representations or understandings between the parties. You accept the terms of this EULA either by clicking to accept or agree to the terms or by actually using the software, in which case you understand and agree that we will treat your use of the software as acceptance of the terms of this EULA. from now on. If you do not agree to the terms of this EULA, do not install or use the WOT-STATS software.
            The WOT-STATS software is protected by copyright laws and international copyright treaties, as well as other intellectual property laws and treaties.
          </p>
          <p>
            1. LICENSE GRANT.
            <br/>
            The WOT-STATS software has the following licenses:
            <br/>
            (а) Installation and use.
            <br/>
            WOT-STATS grants you the right to install and use copies of the WOT-STATS software on your computer with a valid licensed copy of the operating system.
            <br/>
            (б) Backups.
            <br/>
            You may also make copies of the WOT-STATS software if necessary for backup and archival purposes..
          </p>

          <p>
            2. DESCRIPTION OF OTHER RIGHTS AND LIMITATIONS.
            <br/>
            (а) Retention of copyright notices.
            <br/>
            You must not remove or alter any copyright notices on any copy of the software without exception. WOT-STATS.
            <br/>
            (б) Spreading.
            <br/>
            You may not distribute registered copies of the WOT-STATS software to third parties.
            <br/>(c) Reverse engineering, decompilation and disassembly prohibited.
            <br/>You may not reverse engineer, decompile, or disassemble the WOT-STATS Software, except and only to the extent that such activity is expressly permitted by applicable law notwithstanding this limitation..
            <br/>(d) Rent.
            <br/>You may not rent or provide the software WOT-STATS.
            <br/>(e) Support Services.
            <br/>WOT-STATS may provide you with support services related to the WOT-STATS software ("Support Services"). Any additional programming code provided to you as part of the Support Services is considered part of the WOT-STATS software and is subject to the terms of this EULA.
            <br/>(f) Compliance with Applicable Laws.
            <br/>You must comply with all applicable laws regarding the use of the software WOT-STATS.
          </p>
          <p>
            3. Suspension
            <br/>Without prejudice to any other rights, WOT-STATS may terminate this EULA if you fail to comply with the terms of this EULA. In such event, you must destroy all copies of the WOT-STATS Software in your possession..
          </p>

          <p>
            4. COPYRIGHT
            <br/>All rights, including but not limited to copyright in the WOT-STATS software and any copies thereof, are owned by WOT-STATS or its suppliers. All ownership and intellectual property rights in and to content that can be accessed using the WOT-STATS software are the property of the respective content owner and may be protected by applicable copyright or other intellectual property laws and treaties. This license agreement does not give you any rights to use such content. All rights not expressly granted are reserved by WOT-STATS.
          </p>
          <p>
            5. NO WARRANTY
            <br/>WOT-STATS expressly disclaims any warranties for the WOT-STATS software. The WOT-STATS software is provided "as is" without any express or implied warranties of any kind, including, without limitation, any warranties of merchantability, non-infringement, or fitness for a particular purpose. WOT-STATS does not warrant or assume responsibility for the accuracy or completeness of any information, text, graphics, links or other items contained in the WOT-STATS software. As part of its operating procedure, WOT-STATS may have access to private information such as passwords, but does not guarantee or accept responsibility for any loss or misuse of your personal information that may be caused by the use of our software. WOT-STATS makes no warranties regarding any harm that may be caused by the transmission of a computer virus, worm, time bomb, logic bomb, or other similar computer program. WOT-STATS also expressly disclaims any warranties or representations to Authorized Users or any third party.
          </p>
          <p>
            6. LIMITATION OF LIABILITY

            <br/>Under no circumstances shall WOT-STATS be liable for any damages (including, but not limited to, loss of profits, business interruption, or loss of information) resulting from the use of "Authorized Users" or the inability to use the WOT-STATS software, even if WOT-STATS STATS has been notified of this. the possibility of such damage. Under no circumstances shall WOT-STATS be liable for loss of data or for indirect, special, incidental, consequential (including lost profits) or other damages whether in contract, tort or otherwise. WOT-STATS is not responsible for the content of the WOT-STATS software or any part thereof, including, but not limited to, errors or omissions contained therein, defamation, infringement of publicity rights, confidentiality, trademark rights, business interruption, personal injury , losses. privacy, moral rights or disclosure of confidential information.
          </p>
          E-mail: <b>help@wot-stats.fun</b>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="$store.dispatch('eula/terms', false)"
          >Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      transition="dialog-top-transition"
      max-width="80%"
      v-model="$store.state.eula.politics"
    >
      <v-card>
        <v-toolbar
          dark
        > Security policy WOT-STATS.</v-toolbar>
        <v-card-text>
          <p>
            <br/>
            General provisions of the security policy.
            <br/>
            WOT-STATS protects the privacy of personal data of customers who visit this website and use its services. Amendments to this privacy policy, if any, will be posted on WOT-STATS and will become effective immediately after they are posted. Your continued use of our service following the posting of any changes to our privacy policy constitutes your acceptance of those changes..
          </p>
          <p>
            Collection and storage of information.
            <br/>
            WOT-STATS collect, store and use user information solely to provide our services, as well as inform users about changes in prices, terms of service, promotions and introductory materials.
          </p>

          <p>
            Use of personal information.
            <br/>
            We may use personal information for the following purposes:
            <br/>
          <ul>
            <li> to inform about the status of the order and all related work on the order.</li>
            <li> to improve the quality of service and the operation of the service as a whole.</li>
            <li> to send information related to changes on the site.</li>
          </ul>
          </p>

          <p>
            Protection of personal information.
            <br/>
            WOT-STATS the following personal data protection methods are used:
            <br/>
          <ul>
            <li>SSL encryption protocol with which we protect all personal data.</li>
            <li>Acceptance of data on credit cards and other payment methods occurs through the payment buffer and not directly through WOT-STATS.</li>
            <li>All security measures have been taken on the current hosting. The server is regularly checked for malware to protect personal data.</li>
          </ul>
          </p>
          <p>

            <br/>Providing information to third parties.
            <br/>WOT-STATS  under no circumstances transfers user data and any other information provided by the user to third parties.
            <br/>The use of your personal data on third-party sites already falls under the privacy policy of these same sites..
          </p>
          <p>
            Deletion of personal data.
            <br/>Users and customers can always delete their data in one of the following ways:
            <br/>By email: write to mail <b> help@wot-stats.fun </b>
          </p>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="$store.dispatch('eula/politics', false)"
          >Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="$store.state.showError"
      timeout="5000"
    >
      {{ $store.state.error }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"

          @click="$store.dispatch('displayError', {isHidden: true, text: ''})"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-app>
</template>

<script>

import { VIR_DIRECTORY } from "@/config";

export default {
  data: () => ({
    drawer: null,
    dialog: false,
    authDialog: false,
    languages: ["EN", "UA", "RU"],
    authData: {email: "", password: ""},
    routes: [{ icon: "mdi-account-search", text: "search", link: `${VIR_DIRECTORY}/`, onlyAuth: false },
             { isSpacer: true, isSubHeader: true, text: "privatechat", link: "",onlyAuth: true},
             { icon: "mdi-cogs", text: "privatchatsettings", link: `${VIR_DIRECTORY}/chat/private/settings`, onlyAuth: true},
             { icon: "mdi-cloud-outline", text: "queue", link: `${VIR_DIRECTORY}/chat/private/queue`, onlyAuth: true },
             { icon: "mdi-history", text: "history", link: `${VIR_DIRECTORY}/chat/private/history`, onlyAuth: true },
             { isSpacer: true, isSubHeader: true, text: "publicchat", link: "",onlyAuth: true},
             { icon: "mdi-cogs", text: "privatchatsettings", link: `${VIR_DIRECTORY}/chat/public/settings`, onlyAuth: true},
             { isSpacer: true, isSubHeader:false, text: "", link: "",onlyAuth: true},
             { icon: "mdi-download", text: "download", link: `${VIR_DIRECTORY}/download`, onlyAuth: false },
             { icon: "mdi-currency-usd", text: "donate", link: `${VIR_DIRECTORY}/donate`, onlyAuth: false },
             // { icon: "mdi-face-agent", text: "Поддержка", link: "/support", onlyAuth: false  },
             { isSpacer: true, isSubHeader:false, text: "", link: "",onlyAuth: false},
             { icon: "mdi-shield-crown", text: "devsettings", link: `${VIR_DIRECTORY}/dev/settings`, onlyAuth: false },
    ],
  }),

  methods: {
    Authorize() {
      this.$store.dispatch("auth/login", this.authData);
      this.authDialog = false;
    },
  },

  mounted() {
    this.$store.dispatch("getCountryCode");

    this.$store.dispatch("getBackendStatus");

    const ver = localStorage.getItem("ver");

    if (!ver) {
      localStorage.setItem("ver", "1.45");
    }
    if (ver && ver != "1.45") {
      localStorage.setItem("ver", "1.45")
      location.reload(true);
    }

  },
  watch: {
    '$store.state.selectedLanguage' : function() {
      this.$i18n.locale = this.$store.state.selectedLanguage;
    }
  }

};
</script>

<style lang="scss">
.nav-item {
  margin-right: 10px;
}

.cursor-pointer {
  cursor: pointer;
}
.backend-status {
  padding: 1rem;
}
</style>
