export const eula = {
  namespaced: true,
  state: {
    politics: false,
    term: false
  },
  mutations: {
    Politics(state, showPolitics: boolean) {
      state.politics = showPolitics;
    },
    Terms(state, showTerms: boolean) {
      state.term = showTerms;
    }
  },
  actions: {
    politics({commit}, isHidden: boolean) {
      commit("Politics", isHidden)
    },
    terms({commit}, isHidden: boolean) {
      commit("Terms", isHidden)
    }
  }
}
