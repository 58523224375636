<template>
  <div class="container">
    <h1 class="text-center">{{$t('settings.title')}} {{this.$store.state.auth.user.userName}}! </h1>
    <hr>
    <br>
    <div class="container col-8 offset-2">
      <form @submit.prevent="submit">
        <v-textarea
          v-model="$store.state.modPublicSettings.message"
          v-bind:label="$t('settings.message')"
          required
          outlined
          no-resize
          counter
          filled
        ></v-textarea>

        <v-select multiple :items="$store.state.WoTServers" v-model="$store.state.modPublicSettings.servers" label="Servers">

        </v-select>

        <v-btn
          class="mr-4"
          type="submit"
        >
          {{$t('settings.save')}}
        </v-btn>
      </form>
    </div>
  </div>



</template>

<script>
export default {
  name: "PublicSettings",
  data: () => ({
  }),
  mounted() {
    this.$store.dispatch("getModPublicSettings");
    this.$store.dispatch("getWoTServers");
  },
  methods: {
    submit() {
      this.$store.dispatch("updateModPublicSettings");
    }
  }
}
</script>

<style scoped>

</style>
