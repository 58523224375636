var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn',{staticStyle:{"margin":"1rem"},attrs:{"color":"primary","disabled":_vm.$store.state.isLoading},on:{"click":_vm.addPlayersToQueue}},[_vm._v(" "+_vm._s(_vm.$t('table.addplayers', {count: _vm.selected.length})))]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.$store.state.isLoading? []: _vm.getterplayers,"single-select":_vm.singleSelect,"item-key":"nickname","option":"","show-select":"","loading":_vm.$store.state.isLoading,"disable-pagination":_vm.$store.state.isLoading,"disable-sort":_vm.$store.state.isLoading,"disable-filtering":_vm.$store.state.isLoading,"loader-height":"15px","loading-text":"Loading"},scopedSlots:_vm._u([{key:"item.nickname",fn:function(ref){
var item = ref.item;
return [_c('a',{staticStyle:{"text-decoration":"none","font-weight":"bold"},attrs:{"target":"_blank","href":("https://" + (item.server === 'eu'? 'eu' : 'ru') + ".wargaming.net/clans/wot/search/#wgsearch&type=accounts&search=" + (item.nickname) + "&account_id=" + (item.accountId) + "&limit=10&accounts-battle_type=fort_battles&accounts-timeframe=28")}},[_vm._v(" "+_vm._s(item.nickname)+" ")])]}},{key:"item.wn8",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getwn8color(item.wn8),"dark":""}},[_vm._v(" "+_vm._s(item.wn8.toFixed(1))+" ")])]}},{key:"item.wn_1000",fn:function(ref){
var item = ref.item;
return [(!item.wn_1000)?_c('v-chip',{attrs:{"color":_vm.getwn8color(item.wn_1000),"dark":""}},[_c('span',[_vm._v(" [DISABLED] ")])]):_vm._e(),(item.wn_1000)?_c('v-chip',{attrs:{"color":_vm.getwn8color(item.wn_1000),"dark":""}},[_vm._v(" "+_vm._s(item.wn_1000.toFixed(1))+" ")]):_vm._e()]}},{key:"item.winrate",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getwincolor(item.winrate),"dark":""}},[_vm._v(" "+_vm._s(item.winrate.toFixed(1))+" ")])]}},{key:"item.playerTanks",fn:function(ref){
var item = ref.item;
return _vm._l((item.playerTanks),function(tank){return _c('span',{key:item.nickname + tank.name},[_vm._v(" "+_vm._s(tank.name)+" ("+_vm._s(tank.strongholdDefenseAvgDmg)+") ")])})}},(_vm.$store.state.isAuthorized)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"green"},on:{"click":function($event){return _vm.add(item)}}},[_vm._v(" mdi-plus ")]),(_vm.$store.state.playerQueue.find(function (x){ return x.accountId == item.accountId; }))?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.remove(item)}}},[_vm._v(" mdi-minus ")]):_vm._e()]}}:null],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }