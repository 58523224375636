export const MAIN_API = "https://api.wot-stats.fun/api";
export const LOCAL_API = "https://localhost:44352/api"
export const NEXT_API = "https://next-api.wot-stats.ru/api"

export const API = MAIN_API


export const VIR_DIRECTORY = "/search";

export const SERVICE_NAME = "search.wot-stats"
